import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Section1() {
  useEffect(() => {
    AOS.init();
  });
  return (
    <div className="w-full min-h-screen">
      <div className="mx-auto">
        <div className="w-full bg-stone-900 bg-opacity-80 h-screen pt-20">
          <div
            data-aos="fade-up"
            data-aos-delay="300"
            className="container mx-auto"
          >
            <div className="text-white bold text-9xl font-bold">
              코리아밸리
              <br />
              상담의 A to Z<br />
              <span className="text-6xl font-normal">
                #쇼핑몰#콜센터#CS대행
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section1;
