import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import imgA from "../Asset/Section2/01.png";
import imgB from "../Asset/Section2/02.png";
import imgC from "../Asset/Section2/03.png";
import imgD from "../Asset/Section2/04.png";

function Section2() {
  useEffect(() => {
    AOS.init();
  });
  return (
    <div className="mx-auto">
      <div className="w-full bg-gray-200 py-20">
        <div data-aos="fade-up" className="container mx-auto">
          <div className="text-teal-500 bold text-6xl font-bold mb-10">
            코리아밸리는
          </div>
        </div>
        <div data-aos="fade-up" className="container mx-auto">
          <div className="text-black bold text-3xl font-normal mt-10">
            다년간의 콜센터 운영 경험을 바탕으로
            <br />
            체계적이고 안정적인 콜센터 시스템을 보유하고 있습니다
            <br />
            <br />
            인사관리에 대한 부담을 감소시키며 부대비용을 절감하고
            <br />
            여기에 질 높은 상담서비스를 제공하고 있는 당사의 시스템에 대해
            <br />
            많은 기업들의 관심과 이용문의 바랍니다.
          </div>
        </div>
        <div data-aos="fade-up" className="container mx-auto">
          <div className="bg-white p-2 w-full rounded-lg mt-10 grid grid-cols-2 xl:grid-cols-4 gap-3">
            <div className="p-3 bg-gray-50">
              <img src={imgA} className="w-1/3 mx-auto" alt="매니저" />
              <div className="text-center text-2xl text-teal-500 font-bold mt-5">
                매니저
              </div>
              <div className="text-center text-xl text-black font-normal">
                인력관리, 상담품질개선, 효율 관리
                <br />
                친절교육 등 관리 총괄
              </div>
            </div>
            <div className="p-3 bg-gray-50">
              <img src={imgB} className="w-1/3 mx-auto" alt="CS상담원" />
              <div className="text-center text-2xl text-teal-500 font-bold mt-5">
                CS상담원
              </div>
              <div className="text-center text-xl text-black font-normal">
                관련 업종 경력이 많은
                <br />
                전문 상담원 전담배치
              </div>
            </div>
            <div className="p-3 bg-gray-50">
              <img src={imgC} className="w-1/3 mx-auto" alt="시설" />
              <div className="text-center text-2xl text-teal-500 font-bold mt-5">
                시설
              </div>
              <div className="text-center text-xl text-black font-normal">
                상담좌석, PC, 교육실, 카페테리아 등
              </div>
            </div>
            <div className="p-3 bg-gray-50">
              <img src={imgD} className="w-1/3 mx-auto" alt="시스템" />
              <div className="text-center text-2xl text-teal-500 font-bold mt-5">
                시스템
              </div>
              <div className="text-center text-xl text-black font-normal">
                녹취, 콜 통계 및 분석 시스템
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
