import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Section3() {
  useEffect(() => {
    AOS.init();
  });
  return (
    <div className="mx-auto">
      <div className="w-full bg-stone-900 bg-opacity-80 py-20">
        <div data-aos="fade-up" className="container mx-auto">
          <div className="text-white bold text-6xl font-bold">
            쇼핑몰 CS(콜센터) 대행 업무진행 절차
          </div>
        </div>
        <div data-aos="fade-up" className="container mx-auto">
          <div className="text-white bold text-3xl font-normal mt-10">
            대부분의 쇼핑몰에서는 CS업무의 중요성에 대해서는 충분히 인식을 하고
            있으나
            <br /> 관련 업무 전체를 외부업체에 맡기는 것에 대해서는 주저하게
            되곤 합니다.
            <br />
            <br /> 이는 달리 말하면, 전문업체에 업무를 이관하고 골치아픈
            CS업무에서 벗어나고 싶은 <br />
            욕망은 간절하나 쉽사리 쇼핑몰의 운명을 좌우할 수 있는 주요업무를
            <br />
            외부업체에 위탁하기는 두려워 하는 심리라 생각됩니다. <br />
            <br />
            <strong>코리아밸리</strong> 는 쇼핑몰들의 이러한 우려를 깨끗이
            해소해 드리는 것이 <br />
            무엇보다 중요하다고 인지하고 있으며
            <br />
            <br />
            이에 아래와 같은 체계적인 절차로 쇼핑몰 CS대행에 관한 제안을
            드립니다.
          </div>
        </div>
        <div data-aos="fade-up" className="container mx-auto">
          <div className="bg-white p-2 w-full rounded-lg mt-10 grid grid-cols-1 xl:grid-cols-3 xl:gap-5">
            <div>
              <h2 className="bg-black py-2 px-5 text-xl font-bold text-white rounded-r-full grid grid-cols-2">
                <div className="text-left">사전 준비</div>
                <div className="hidden xl:block text-right">▶▶▶</div>
              </h2>
              <div className="flex flex-row flex-nowrap justify-between px-5 py-2 text-lg">
                <div className="basis-2/3 text-left font-medium">
                  고객사 최초 미팅
                </div>
                <div className="basis-1/3 text-right">
                  <span className="text-red-500 font-medium">1일</span> 소요
                </div>
              </div>
              <div className="flex flex-row flex-nowrap justify-between px-5 py-2 text-lg">
                <div className="basis-2/3 text-left font-medium">
                  고객사 시스템 점검 및 업무공유
                </div>
                <div className="basis-1/3 text-right">
                  <span className="text-red-500 font-medium">1일</span> 소요
                </div>
              </div>
              <div className="flex flex-row flex-nowrap justify-between px-5 py-2 text-lg">
                <div className="basis-2/3 text-left font-medium">
                  시스템 설치 및 교육
                </div>
                <div className="basis-1/3 text-right">
                  <span className="text-red-500 font-medium">3일</span> 소요
                </div>
              </div>
            </div>
            <div>
              <h2 className="bg-black py-2 px-5 text-xl font-bold text-white rounded-r-full grid grid-cols-2">
                <div className="text-left">최종 준비</div>
                <div className="hidden xl:block text-right">▶▶▶</div>
              </h2>
              <div className="flex flex-row flex-nowrap justify-between px-5 py-2 text-lg">
                <div className="basis-2/3 text-left font-medium">
                  업무 분석 완료 및 제안
                </div>
                <div className="basis-1/3 text-right">
                  <span className="text-red-500 font-medium">3일</span> 소요
                </div>
              </div>
              <div className="flex flex-row flex-nowrap justify-between px-5 py-2 text-lg">
                <div className="basis-2/3 text-left font-medium">업무 조율</div>
                <div className="basis-1/3 text-right">
                  <span className="text-red-500 font-medium">1일</span> 소요
                </div>
              </div>
              <div className="flex flex-row flex-nowrap justify-between px-5 py-2 text-lg">
                <div className="basis-2/3 text-left font-medium">
                  최종 정비{" "}
                  <small className="font-normal">(수수료 선결제)</small>
                </div>
                <div className="basis-1/3 text-right">
                  <span className="text-red-500 font-medium">3일</span> 소요
                </div>
              </div>
            </div>

            <div>
              <h2 className="bg-black py-2 px-5 text-xl font-bold text-white">
                <div className="text-left">업무 시작</div>
              </h2>
              <div className="px-5 py-2 text-lg text-left font-medium">
                업무 분석 완료 및 제안
              </div>
              <div className="px-5 py-2 text-lg text-left font-medium">
                일일 업무보고서 제출
              </div>
              <div className="px-5 py-2 text-lg text-left font-medium">
                현황보고서 지출 및 방문 브리핑(반기별)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
