import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Section4() {
  useEffect(() => {
    AOS.init();
  });
  return (
    <div className="mx-auto">
      <div className="w-full bg-gray-200 bg-opacity-100 py-20">
        <div data-aos="fade-up" className="container mx-auto">
          <div className="text-teal-500 bold text-6xl font-bold">
            쇼핑몰 CS대행의 기대효과
          </div>
        </div>
        <div data-aos="fade-up" className="container mx-auto">
          <div className="text-gray-700 bold text-3xl font-normal mt-10">
            코리아밸리는 다년간의 콜센터 운영노하우를 고객사(쇼핑몰)와 공유하여
            <br />
            진정한 사업파트너로 자리매김 하고자 합니다
            <br />
            <br />
            고객사는 콜센터 전문업체가 제공하는 쇼핑몰 운영에 관련된 주요
            서비스를
            <br />
            추가비용의 부담 없이 제공받을 수 있으며, 아래와 같은 효과를 기대할
            수 있습니다
          </div>
        </div>
        <div data-aos="fade-up" className="container mx-auto">
          <div className="bg-white rounded-lg bold text-2xl font-normal mt-10 p-5">
            <div className="mt-5 mb-10">
              <h2 className="border-b-4 border-black pl-10 pb-2">
                <span className="bg-black text-white py-2 px-5 rounded-t-lg">
                  업무효율 증대
                </span>
              </h2>
              <div className="pl-10 mt-3 text-xl beforeArrow">
                일반 업무와 상담 업무를 병행 → 늘어나는 콜을 감당 못함 → 강성
                클레임 증가 → 직원의 스트레스 증가{" "}
                <span className="font-bold text-red-500">= 직원의 이직</span>
              </div>
              <div className="pl-10 mt-3 text-xl beforeArrow">
                코리아밸리에 위탁 후{" "}
                <span className="font-bold italic">
                  업무집중도 향상 및 효율 증대 / 악성 클레임 대처로 인한 감성
                  노동 Zero
                </span>
              </div>
            </div>
            <div className="my-10">
              <h2 className="border-b-4 border-black pl-10 pb-2">
                <span className="bg-black text-white py-2 px-5 rounded-t-lg">
                  CS업무에 대한 합리적 평가 및 분석
                </span>
              </h2>
              <div className="pl-10 mt-3 text-xl beforeArrow">
                항상 바빠보이는데 업무량은 줄지 않음 → 보람없는 업무로 인한
                회의감 증가
                <span className="font-bold text-red-500">
                  = 상담의 질적 저하
                </span>
              </div>
              <div className="pl-10 mt-3 text-xl beforeArrow">
                코리아밸리에 위탁 후{" "}
                <span className="font-bold italic">
                  콜 통계, 콜 분석 등 합리적인 평가로 문제점 진단 / 일일 CS현황
                  보고로 밀착 관리 가능
                </span>
              </div>
            </div>
            <div className="my-10">
              <h2 className="border-b-4 border-black pl-10 pb-2">
                <span className="bg-black text-white py-2 px-5 rounded-t-lg">
                  기업 이미지 향상 / 충성 고객 증가
                </span>
              </h2>
              <div className="pl-10 mt-3 text-xl beforeArrow">
                정확한 매뉴얼에 근거한 적절한 응대 없음 → 게시판에 악성 댓글
                증가
                <span className="font-bold text-red-500">
                  = 기업 이미지 하락
                </span>
              </div>
              <div className="pl-10 mt-3 text-xl beforeArrow">
                코리아밸리에 위탁 후{" "}
                <span className="font-bold italic">
                  &lt;리스크 관리 매뉴얼&gt;에 근거한 응대로 기업이미지 향상 /
                  고객이탈 방지 및 재구매율 향상
                </span>
              </div>
            </div>
            <div className="my-10">
              <h2 className="border-b-4 border-black pl-10 pb-2">
                <span className="bg-black text-white py-2 px-5 rounded-t-lg">
                  리스크 최소화
                </span>
              </h2>
              <div className="pl-10 mt-3 text-xl beforeArrow">
                직원채용, 기타업무(촬영, 호스팅, 웹디자인 등) 처리의 미숙 → 업무
                적체로 운영 어려움
                <span className="font-bold text-red-500">
                  = 본연의 창조적인 업무(제품 디자인 등)에 집중 못함
                </span>
              </div>
              <div className="pl-10 mt-3 text-xl beforeArrow">
                코리아밸리의{" "}
                <span className="font-bold italic">
                  부가서비스(직원 채용 등) 대행으로 관리 리스크 감소 / 업무
                  집중(마케팅, 영업 등)으로 기업수익 증대
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section4;
