import React from "react";
import logo from "../Asset/logo.png";
import blog from "../Asset/blog.png";

function Footer() {
  return (
    <>
      <div className="bg-gray-200 w-full py-5">
        <div className="container mx-auto grid grid-cols-4 gap-5">
          <div className="flex flex-col justify-center">
            <img src={logo} alt="코리아밸리" className="w-1/2 mx-auto" />
          </div>
          <div className="flex flex-col justify-start gap-2">
            <span className="font-bold">유한회사 코리아밸리</span>
            <span className="font-normal">대표 : 김 태 훈</span>
            <span className="font-normal">
              경기도 남양주시 별내중앙로 26, 10층 1002-에이74호(별내동
              진영엔타워)
            </span>
            <span className="font-normal">사업자등록번호 : 227-87-02579</span>
          </div>
          <div className="flex flex-col justify-start gap-2">
            <span className="font-bold">
              코리아밸리의 다양한 소식들을 만나보세요
            </span>
            <a
              href="https://blog.naver.com/kovalley"
              target="_blank"
              rel="noopener noreferrer"
              className="w-fit"
            >
              <img src={blog} alt="blog" />
            </a>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 text-white p-2 text-center text-sm">
        Copyright ⓒ KoreaValley. All rights reserved
      </div>
    </>
  );
}

export default Footer;
