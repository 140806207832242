import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Section5() {
  useEffect(() => {
    AOS.init();
  });
  return (
    <div className="mx-auto">
      <div className="w-full bg-stone-900 bg-opacity-80 py-20">
        <div data-aos="fade-up" className="container mx-auto">
          <div className="text-white bold text-6xl font-bold">Contact Us</div>
        </div>
        <div data-aos="fade-up" className="container mx-auto">
          <div className="text-white bold text-6xl font-normal mt-10">
            02-2276-0870
          </div>
          <div className="text-white bold text-3xl font-normal my-10">
            코리아밸리의 운영시스템에 관심있으신 분들의 많은 연락 바랍니다.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section5;
