import React from "react";
import bg from "../Asset/background.jpg";
function Bg() {
  return (
    <div className="fixed z-10 bg-green-500 w-full h-full top-0 bottom-0 right-0 left-0 overflow-hidden">
      <img
        src={bg}
        className="object-center min-w-fit min-h-full mx-auto"
        alt="bg"
      />
    </div>
  );
}

export default Bg;
